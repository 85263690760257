.editContainer {
    margin: 0 10px;
}

.addComments textarea {
    padding-bottom: 30px
}

.buttonContainer {
    position: absolute;
    right: 16px;
    bottom: 32px;
    padding: 0;
    height: 30px;
    display: flex;
    gap: 16px;
}

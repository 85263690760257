.guestLayoutWrapper {
    display: flex;
    height: 100vh;
    width: 100%;
    @media screen and (max-width: 991px) {
        flex-direction: column;
    }
    .guestLayoutBanner {
        background-color: #0b1e60;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;

        @media screen and (min-width: 1201px) {
            width: 100%;
            max-width: 572px;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            width: 50%;
        }
    }
    .guestLayoutContent {
        height: 100%;
        @media screen and (min-width: 1201px) {
            width: calc(100% - 572px);
        }
        @media screen and (min-width: 992px) {
            overflow-x: hidden;
            min-height: 100%;
        }
        @media screen and (min-width: 992px) and (max-width: 1200px) {
            width: 50%;
        }
        .glContent {
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (min-width: 992px) {
                min-height: 100%;
            }
        }
    }
  .logoImg {
    width: 362px
  }
}

:root {
  --primary-color: #0b1e60;
  --primary-color-hover: #000c37;
  --app-font-family: "AvenirLTProRoman";
}

[class*="ag-theme-"] {
  font-family: var(--app-font-family) !important;
}

body {
  font-family: "AvenirLTProRoman";
}

img {
  max-width: 100%;
}

.text-primary {
  color: $text-primary;
}

.font-weight {
  &-normal {
    font-family: "AvenirLTProRoman";
  }

  &-light {
    font-family: "AvenirLTProLight";
  }

  &-book {
    font-family: "AvenirLTProBook";
  }

  &-medium {
    font-family: "AvenirLTProMedium";
  }

  &-heavy {
    font-family: "AvenirLTProHeavy";
  }

  &-black {
    font-family: "AvenirLTProBlack";
  }
}

.ant-layout {
  min-height: 100vh;

  &-sider {
    box-shadow: 2px 0px 8px rgba(0, 0, 0, 0.15);
    // margin-right: 1px;

    &-logo {
      margin: 17px auto 17px 17px;
    }
  }

  &-header {
    @extend .bg-white;
  }

  &.guest-layout {
    .ant-layout-content {
      @extend .d-flex, .align-items-center, .position-relative;
    }
  }
}

.global-spinner {
  @extend .position-absolute, .w-100, .h-100;
  left: 0%;
  top: 0%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1100;
  .ant-spin-dot-spin {
    @extend .position-absolute;
    top: 50%;
    left: 50%;
    margin: -20px 0 0 -20px;
  }

  &.ant-spin {
    position: fixed !important;
  }

  &.without-sidebar {
    z-index: 900;
    left: 250px;
    width: calc(100% - 250px) !important;
  }
}

.ant-form-item-explain,
.ant-form-item-extra {
  font-size: 11px;
}

.app-header-right {
  float: right;

  .loggedin-user-dd {
    & > a {
      @extend .font-weight-medium;
      color: #262626;

      .ant-avatar,
      .anticon {
        margin: 0 5px;
      }

      .anticon {
        &-down {
          color: #8c8c8c;
        }
      }
    }
  }
}

.menu-list-item {
  &.ant-menu-item-selected {
    background-color: var(--primary-color-hover) !important;
  }
}

.commissionTable {
  .ant-table-thead {
    & > tr {
      & > th {
        font-size: 14px;
        font-weight: 500 !important;
        font-family: "Roboto", sans-serif;
      }
    }
  }

  .ant-table-tbody {
    & > tr {
      & > td {
        font-size: 14px;
        font-weight: 400;
        font-family: "Roboto", sans-serif;
        color: #525252;
      }
    }
  }
}

.table-details {
  .tableRow {
    border-bottom: none;
  }
}

.ag-theme-alpine.s2-theme-style {
  .ag-root-wrapper {
    border: 0 !important;
    // padding-bottom: 10px !important;
  }

  .ag-header,
  .ag-row {
    border-color: rgba(0, 0, 0, 0.06) !important;
  }

  .ag-header {
    background-color: #fafafa;
    font-size: 14px;
    font-family: "AvenirLTProBook";
    line-height: 22px;
  }

  .text-center .ag-header-cell-label {
    flex-direction: row;
    justify-content: center;
  }

  .ag-cell {
    font-family: "AvenirLTProBook";
    font-weight: 500;
    // background-color: white;
    font-size: 14px;
  }

  .ag-tabs-body,
  .ag-menu-list {
    font-weight: 400;
  }
}

.ant-modal {
  &.s2-theme-style {
    border-radius: 8px;

    .ant-modal-header {
      border: 0;

      .ant-modal-title {
        font-weight: 600;
        font-family: "AvenirLTProBook";
        font-size: 20px;
        line-height: 28px;
        color: #131523;
        letter-spacing: 1px;

        .rolesTableModal {
          font-size: 14px;
          color: #131523;
          font-family: "AvenirLTProBook";
        }
      }
    }

    .rolesTableTypography {
      font-size: 14px;
      font-family: "AvenirLTProBook";
      color: rgba(0, 0, 0, 0.85);
    }

    .ant-modal-body {
      padding-top: 0px;
      padding-bottom: 0px;

      .rolesTableInput {
        max-width: 333px;
        width: 100%;
      }
    }

    .ant-modal-footer {
      border: 0;
      padding-top: 22px;
      padding-bottom: 22px;

      .ant-btn {
        margin-right: 10px;
      }
    }

    .ant-transfer {
      justify-content: space-between;
    }

    .ant-form-item-label {
      font-size: 14px;
      font-family: "AvenirLTProBook";
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      font-style: normal;

      .ant-form-item-required {
        display: flex;
        flex-direction: row-reverse;
      }
    }

    .modalInput {
      border-radius: 5px;
    }

    .datePickerModal {
      width: 100%;
      border-radius: 5px;
    }

    .icon-closeable {
      font-size: 10px;
      color: #7e84a3;
      position: absolute;
      top: 26px;
      left: 20px;
    }

    &.tierModal {
      .ant-modal-body {
        padding-top: 40px;
        padding-bottom: 50px;
      }
    }
  }

  &.billingClient {
    .clientJobsField2 {
      margin-bottom: 11px;
    }

    .checkbox {
      margin: 0px;
    }
  }

  &.jobsList {
    .formItemLocation {
      margin-bottom: 0px;

      @media screen and (max-width: 575px) {
        margin-bottom: 30px;
      }

      .ant-checkbox-wrapper {
        margin-top: 5px;
      }
    }

    .checkboxModal {
      text-align: end;
      margin-bottom: 30px;
    }
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1890ff;
  text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab-btn {
  font-family: "AvenirLTProBook";
  font-weight: 600;
}

.ant-tabs-ink-bar {
  position: absolute;
  background: #1890ff;
  pointer-events: none;
}

.recurringCheckboxGroup {
  label.ant-checkbox-wrapper-checked {
    & > span {
      &:nth-child(2) {
        background-color: #1e326e;
        color: #fff;
      }
    }
  }
}

.sidebarFixed {
  &.ant-layout-sider-collapsed {
    .siderProfile {
      display: none;
    }
  }

  &:not(.ant-layout-sider-collapsed) {
    & + .open-sider-overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.7);
      top: 0;
      left: 0;
      z-index: 1;
      animation: 0.3s ease 0s normal forwards 1 fadein;
    }

    .sider-popover {
      -webkit-animation: 1s ease 0s normal forwards 1 fadein;
      animation: 1s ease 0s normal forwards 1 fadein;
    }

    .siderProfile {
      -webkit-animation: 1s ease 0s normal forwards 1 fadein;
      animation: 1s ease 0s normal forwards 1 fadein;
    }

    @keyframes fadein {
      0% {
        opacity: 0;
      }

      30% {
        opacity: 0.5;
      }

      55% {
        opacity: 0.8;
      }

      100% {
        opacity: 1;
      }
    }

    @-webkit-keyframes fadein {
      0% {
        opacity: 0;
      }

      30% {
        opacity: 0.5;
      }

      55% {
        opacity: 0.8;
      }

      100% {
        opacity: 1;
      }
    }
  }
}

.siderWrap {
  @media print {
    display: none;
  }

  .ant-layout-sider-children {
    height: calc(100vh);
  }
}

.responsiveCollapedButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: #1e326e !important;
  background: #1e326e !important;
}

.candidates-info-card {
  .ant-tabs-nav {
    &::before {
      border-color: #d6ddeb;
    }
  }

  .ant-tabs-tab:first-child {
    margin-left: 1.5rem;
  }

  .ant-tabs-tab {
    .cic-tabs-label-style {
      font-weight: 600;
      font-size: 16px;
      line-height: 160%;
    }

    &:not(.ant-tabs-tab-active) {
      .cic-tabs-label-style {
        color: #25324b;
      }
    }

    &.ant-tabs-tab-active {
      .cic-tabs-label-style {
        color: #278dc0;
      }
    }
  }

  .ant-tabs-nav-list {
    .ant-tabs-ink-bar {
      height: 4px;
      background: #278dc0;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
    }
  }
}

.ant-input-number {
  width: 100%;
}

.invoiceTable {
  tr > th {
    font-family: "AvenirLTProBook";
    font-weight: 600;
    font-size: 14px;
  }

  h5.ant-typography {
    font-size: 15px;
    font-family: "AvenirLTProBook";
    font-weight: 600;
    min-width: 100px;

    .textwrap {
      white-space: nowrap;
    }
  }
}

.ant-picker {
  width: 100%;
}

.backButton {
  padding: 0;

  & > i {
    font-size: 10px;
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.85);
  }

  & > span {
    color: rgba(0, 0, 0, 0.85);
    font-weight: 600;
  }
}

.formDatepicker {
  width: 100%;
}

.ant-form-item-required {
  flex-direction: row-reverse;
}

.ant-form-item-required::before {
  margin-right: 0 !important;
  margin-left: 4px !important;
  margin-top: -1px !important;
  font-family: normal !important;
}

.progress-bar-style {
  .ant-progress-text {
    display: none;
  }

  &.ant-progress-show-info .ant-progress-outer {
    padding-right: calc(8px);

    .ant-progress-inner {
      background-color: #d6ddeb;
      border-radius: 0;
    }
  }
}

// profile page scss

.image-uploaded {
  .ant-upload-select-picture-card {
    border-radius: 50%;
    border: 2px solid #d1d1d9;
  }

  .ant-upload-list-picture-card-container {
    .ant-upload-list-item-list-type-picture-card {
      border: none;
      padding: 0;
      border-radius: 50%;
      border: 2px solid #d1d1d9;

      .ant-upload-list-item-info {
        border-radius: 50%;
      }
    }
  }
}

.profile-image-wrapper {
  position: relative;
  max-width: 105px;
  margin-bottom: 83px;

  .change-image-icon {
    position: absolute;
    bottom: 13px;
    right: 0;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    background-color: #f4f4f4;
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    cursor: pointer;
  }
}

.required-mark-right {
  .ant-form-item-required {
    flex-direction: row-reverse;
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    color: #515b6f;
  }

  .select-suffix-Icon {
    font-size: 6px;
    color: #25324b;
  }

  .date-picker-suffix-Icon {
    color: #25324b;
  }
}

.date-of-birth-date-picker {
  width: 100%;

  .ant-picker-suffix {
    display: flex;
  }
}

.siderEllipsis {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.LookupTablePageHeader {
  .ant-select {
    min-width: 220px;
    width: 100%;
  }

  @media screen and (max-width: 575px) {
    .ant-page-header-heading {
      flex-direction: column;
    }

    .ant-space {
      display: block;
    }
  }
}

form {
  label {
    font-weight: 500;
    font-family: "AvenirLTProMedium";
  }
}

.reasonLabel {
  .plus-icon,
  .plus-icon:hover {
    background: none;
    border: none;
    box-shadow: none;
  }
}

.form-label {
  font-weight: 500;
  font-family: "AvenirLTProMedium";
  margin-bottom: 8px;
}

.appCard {
  .ant-card-extra {
    @media screen and (max-width: 575px) {
      margin-top: 16px;
    }
  }
}

.viewTags {
  background-color: white;
  color: #5d5fef;
  font-weight: 400;
  font-size: 10px;
  border-color: #5d5fef;
  margin-bottom: 10px;
  margin-top: 4px;
}

// .ant-modal-mask {
//   z-index: 1058;
// }

// .ant-modal-wrap {
//   z-index: 1060;
// }

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #f0f0f0;
}

.icon-edit {
  color: #bbbbbb;
}

.candidateSearch {
  @media screen and (max-width: 576px) {
    padding-top: 0 !important;
  }
}

.ant-modal-title {
  padding-right: 40px;
}

.ant-avatar {
  min-width: 32px;
}

.steps-action {
  @media screen and (max-width: 480px) {
    padding-right: 24px;
  }
}

.newCommissionPlan {
  margin-top: 0 !important;
  margin-bottom: 18px !important;
}

.new-commission-title {
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 15px;
}

.ant-transfer-list-content-item:not(
    .ant-transfer-list-content-item-disabled
  ):hover {
  background-color: transparent;
}

.ant-transfer-list-content-item-checked {
  background-color: transparent;
}

.ant-transfer-list-content-item:not(
    .ant-transfer-list-content-item-disabled
  ).ant-transfer-list-content-item-checked:hover {
  background-color: transparent !important;
}

.rolesPermissionTransfer {
  @media screen and (max-width: 576px) {
    display: block !important;
  }

  .ant-transfer-list-with-pagination {
    @media screen and (max-width: 576px) {
      width: 100% !important;
    }
  }

  .ant-transfer-operation {
    align-items: center;

    @media screen and (max-width: 576px) {
      transform: rotate(90deg);
    }
  }

  .ant-transfer-operation .ant-btn:first-child {
    margin-top: 26px;
    margin-bottom: 26px;
  }
  .ant-transfer-list-with-pagination {
    @media screen and (min-width: 768px) {
      width: calc(100% - 321px);
    }
  }
}

.icon-delet {
  display: flex;
  justify-content: center;
}

.ant-layout-sider-collapsed {
  & + .ant-layout {
    @media screen and (min-width: 992px) {
      margin-left: 80px;
    }
  }

  .ant-btn-circle {
    top: 63px;

    .icon-arrow-right {
      transform: rotate(0deg);
      position: absolute;
      top: 8px;
      left: 9px;
    }
  }

  .sider-popover {
    width: auto !important;

    .icon-sider-wifi {
      display: none;
    }

    span {
      display: none;
    }
  }

  .ant-layout-sider-logo {
    overflow: hidden;
    width: 20px;
    margin: 17px auto;

    a {
      img {
        max-width: none;
      }
    }
  }

  .siderProfile {
    left: 16px;

    :not(:first-child) {
      display: none;
    }
  }
}

.border-clr {
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0b1e60;
  }

  .ant-tabs-ink-bar {
    background-color: #0b1e60;
  }
}

.job-status-options {
  .ant-form-item {
    margin-bottom: 0 !important;
  }
}

.status-count-slider {
  .slick-disabled {
    display: none !important;
  }

  .slick-next {
    right: -16px;

    &::before {
      content: "\e93c";
      font-family: "icomoon" !important;
      color: #0b1e60;
    }
  }

  .slick-prev {
    transform: translateY(-50%) scaleX(-1) rotate(412deg);

    z-index: 2;
    left: -16px;

    &::before {
      content: "\e93d";
      font-family: "icomoon" !important;
      color: #0b1e60;
    }
  }
}

.statistics-card {
  .slick-disabled {
    display: none !important;
  }

  .slick-next {
    right: -20px !important;
    top: 32px;

    &::before {
      content: "\e93c";
      font-family: "icomoon" !important;
      color: #0b1e60;
    }
  }

  .slick-prev {
    transform: translateY(-50%) scaleX(-1) rotate(412deg);

    left: -20px;
    top: 30px;

    &::before {
      content: "\e93d";
      font-family: "icomoon" !important;
      color: #0b1e60;
    }
  }
}

.status-count-slider {
  .slick-track {
    display: flex;
    margin: 0;
    @media screen and (max-width: 575px) {
      width: 100% !important;
    }
    .slick-slide {
      & > div {
        height: 100%;
      }

      &:not(:first-child) {
        margin-left: 20px;
      }
    }

    .slick-slide {
      height: inherit;
      min-height: 100%;
    }
  }
}

.statistics-wrap {
  .slick-track {
    display: flex;

    .slick-slide > div {
      height: 100%;
    }

    .slick-slide {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }

    .slick-slide {
      height: inherit;
      min-height: 100%;
    }
  }

  .slick-slide + .slick-slide {
    position: relative;

    &::after {
      content: " ";
      width: 1px;
      height: 27px;
      background-color: #e5e5e5;
      position: absolute;
      left: -24px;
      top: 13px;
    }
  }
}

.job-card-wrap {
  .ant-col-xl-14 {
    .bottom-card-info-header {
      @media screen and (min-width: 1200px) {
        display: block !important;
      }
    }
  }
}

.user-log-card-select {
  .ant-select-arrow .anticon {
    font-size: 10px;
  }
}

.react-grid-layout {
  // @media print{
  // height: auto !important;
  // }
  .react-grid-item {
    @media print {
      width: 100% !important;
      height: 500px !important;
      position: unset !important;
      transform: none !important;
      float: left;

      canvas {
        width: 100% !important;
        height: 100% !important;
      }
    }
  }
}

.ant-layout-footer {
  @media print {
    display: none;
  }
}

@media print {
  .recruitmentPdf {
    .recruitmentPipelineRow {
      flex-wrap: wrap;

      .ant-col {
        width: 100%;
        max-width: 25%;
      }
    }
  }
}

.pdf-wrap {
  display: none;

  @media print {
    display: block;
  }

  .pdf-header {
    display: flex;
    justify-content: space-between;

    h5 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .report-list-wrap {
    .report-para {
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 1.25;
    }

    h5 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
      margin-top: 0;
    }

    h2 {
      margin-top: 15px !important;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 24px;
      color: #0093c8;
    }

    .report-list {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 600;

      li {
        span {
          margin-left: 4px;
          font-weight: 500;
        }
      }
    }

    .report-list-ul {
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 600;

      li {
        span {
          margin-left: 4px;
          font-weight: 500;
        }
      }
    }
  }

  .header-divider {
    border-bottom: 1px solid #071f60;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.ant-select-selection-placeholder {
  font-weight: 400;
}

.ItDepDeleteIcon {
  margin: auto;

  .icon-delet {
    color: #8c8c8c;

    @media screen and (min-width: 576px) {
      margin-top: 5px;
    }
  }
}

.department-input {
  width: calc(100% - 32px) !important;
}

.departmentField {
  .ant-input {
    width: 100% !important;
  }

  @media screen and (max-width: 575px) {
    margin-bottom: 0;
  }
}

.jobBillingSelect {
  .ant-select-selector {
    font-weight: 500;
  }
}

.successFees {
  .ant-form-item-label {
    & > label {
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      font-family: "AvenirLTProMedium";
    }
  }
}

.recurringCheckbox {
  margin: 8px 8px 0px 0px !important;

  & > span {
    &:first-child {
      opacity: 0;
      visibility: hidden;
      position: absolute;
    }

    &:nth-child(2) {
      background-color: #ccc;
      height: 32px;
      width: 32px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.billed-amount {
  @media screen and (max-width: 512px) {
    display: flex;
    flex-direction: column;
    align-items: unset !important;
  }
}

.submit-billing + .submit-billing {
  margin-top: 24px;
}

.cbOrignalAmount {
  .icon-edit {
    color: #0b1e60;
  }
}

// .siderMenu {
//   &::-webkit-scrollbar {
//     width: 10px;
//   }

//   &::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }

//   &::-webkit-scrollbar-thumb {
//     background: #888;
//     border-radius: 5px;
//   }
// }

// .siderMenu ::-webkit-scrollbar {
//   width: 10px;
// }

// .siderMenu ::-webkit-scrollbar-track {
//   background: #f1f1f1;
// }

// .siderMenu ::-webkit-scrollbar-thumb {
//   background: #888;
// }

.commision-select {
  width: 100%;

  .ant-select-selector {
    @media screen and (min-width: 481px) {
      width: 200px;
    }
  }
}

.user-edit-btn {
  text-align: end;

  button {
    .icon-edit {
      color: var(--primary-color);
    }

    span {
      margin-left: 5px;
      color: var(--primary-color);
    }
  }
}

.commission-default-card {
  padding: 15px;
  background: rgb(245, 245, 245);
  border-radius: 4px;

  h1 {
    margin-top: 0;
  }
}

.commission-client-card {
  padding: 15px;

  h1 {
    margin-top: 0;
  }
}

.ant-modal {
  margin: 8px auto;

  @media screen and (max-width: 767px) {
    max-width: calc(100vw - 38px);
  }
}

.ant-modal-body {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &:-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }
}

.commission-statement-collapsed {
  background-color: #f0f2f5;

  .ant-collapse-item {
    background-color: #0b1e60;
  }
  .ant-collapse-header-text {
    color: white;
    font-size: 16px;
    font-weight: 500;
  }
  .ant-collapse-arrow {
    color: white;
  }
  .ant-collapse-content-active {
    border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
  }
}

.add-title-plus-btn {
  .ant-form-item-explain-error {
    position: absolute;
  }
}

.candidate-view-tabs {
  margin-top: 20px;

  .ant-form {
    .tab-header {
      @media screen and (min-width: 1200px) and (max-width: 1420px) {
        flex-wrap: inherit !important;
        margin-bottom: 10px;
      }

      .activity-reload-btn {
        @media screen and (min-width: 1200px) {
          width: 32px !important;
        }
      }
    }
  }

  .ant-tabs-nav-operations {
    @media screen and (min-width: 768px) {
      display: none !important;
    }
  }
}

.cic-tabs-content {
  &::-webkit-scrollbar {
    width: 6px;
  }

  &:-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 5px;
  }
}

.ag-theme-alpine .ag-paging-panel {
  border-top: none !important;
  font-size: 13px;
}

.modal-overflow-auto {
  .ant-modal-body {
    overflow: auto;
    max-height: calc(100vh - 160px);
  }
}

.tagTextColor {
  color: black;
}

.billing-approval-header {
  & + .billing-approval-header {
    margin-top: 20px;
  }
  .ant-card-head {
    background-color: #0b1e60;
    color: white !important;
  }
}

.billing-radios {
  .ant-form-item-control-input-content {
    text-align: center;
  }
}

.warningAlert {
  margin-bottom: 14px;
  max-width: 400px;
  &.gurantee-warning {
    margin: 0 10px 20px;
    max-width: 100%;
    width: 100%;
    align-items: flex-start;
    .anticon {
      margin-top: 4px;
    }
  }
}

.billingField {
  .ant-input-number {
    max-width: 130px;

    @media screen and (max-width: 360px) {
      margin-top: 10px;
    }
  }
}

.closeIcon {
  color: #e01e5a;
  margin-left: 10px;
}
.checkIcon {
  color: #1a7e35;
  margin-left: 7px;
}

.feeCard {
  border-radius: 10px;
  .feeCardDescription {
    @media screen and (min-width: 768px) {
      padding: 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
    background: rgba(11, 30, 96, 0.1);
    border-radius: 10px;
    .ant-descriptions {
      &-header {
        padding-bottom: 30px;
        border-bottom: 1px solid rgba(11, 30, 96, 0.13);
      }
    }
  }
  .ant-card-body {
    @media screen and (min-width: 768px) {
      padding: 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 10px;
    }
  }
  h5 {
    font-weight: 700;
    color: #000;
    margin-top: 1rem;
    margin-bottom: 4px;
  }
  .desc-text {
    span.ant-typography {
      color: #000;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    a.ant-typography {
      font-weight: 600;
      color: #0b1e60;
      white-space: nowrap;
      &:after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }
}

.feeCardDescription {
  .ant-descriptions {
    &-title {
      font-size: 2.5rem;
      line-height: 2.5rem;
      font-weight: 700;
      color: #0b1e60;
      sup {
        font-size: 1.25rem;
        top: -0.7em;
      }
    }
    &-extra {
      font-size: 1.25rem;
      color: rgba(0, 0, 0, 0.58);
    }
    &-item {
      padding-bottom: 0;
      &-label {
        color: #000;
      }
      &-content {
        color: #000;
        @extend .font-weight-medium, .justify-content-end;
      }
    }
  }
}
.daysLabel {
  .ant-descriptions-item-container {
    justify-content: space-between;
  }
  .ant-descriptions-item-content {
    max-width: 140px;
    text-align: right;
  }
}
.resourceFeeModal {
  .ant-modal-header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background: #f9f9f9;
    .ant-modal-title {
      font-size: 1.25rem;
      color: #0b1e60;
      font-weight: 700;
    }
  }
  .ant-descriptions-header {
    margin-bottom: 10px;
  }
  .ant-divider {
    margin: 15px 0;
  }
  .ant-modal-footer {
    border-top: none;
  }
}

.upcoming-date {
  span {
    color: #0b1e60;
    @extend .font-weight-black;
    margin-right: 5px;
  }
}

.appListingCard {
  .ant-card-head {
    border-bottom: none;
  }
  .ant-card-head-title {
    font-size: 1rem;
    font-weight: 700;
    padding-bottom: 0;
  }
}

.appListingCardPanel {
  background-color: transparent;
  .ant-collapse-header {
    align-items: center;
  }
  .ant-collapse-content-box {
    padding: 16px 0;
  }
}

.tierdetal-card {
  background-color: #f9f9f9;
  border: none;
  border-radius: 10px;
  .ant-card-head {
    border-bottom: none;
    min-height: auto;
    &-title {
      font-size: 1rem;
      font-weight: 700;
    }
  }
  .cbrValue {
    font-size: 14px !important;
    font-weight: 600 !important;
    @extend .font-weight-book;
  }
  .cbrTags {
    .ant-tag {
      font-weight: 600 !important;
      @extend .font-weight-book;
      background-color: #eeeeee;
    }
  }
}

.job-billing-card {
  border-radius: 0;
  height: 100%;
  .ant-card {
    &-head {
      background: #f9f9f9;
      border-bottom: solid 1px rgba(0, 0, 0, 0.05);
      &-title {
        font-size: 1.25rem;
        color: #0b1e60;
        text-align: center;
        font-family: "AvenirLTProHeavy";
        font-weight: 700;
        .icon-commission1 {
          margin-right: 10px;
        }
      }
    }
  }

  .title-ellipse {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > .ant-card-body {
    background: rgba(0, 0, 0, 0.05);
    min-height: calc(100% - 64px);
    display: flex;
    width: 100%;
  }
  .feeCard {
    .ant-descriptions-header {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
    .feeCardDescription {
      padding: 15px 20px;
    }
    .jobFeeDescription {
      background-color: transparent;
      padding: 0;
    }
  }
}

.retention-card {
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 5px;
  .ant-card-head-title {
    text-align: left;
  }
  h5 {
    font-weight: 600;
    @extend .font-weight-book;
    color: #0b1e60;
    margin: 0;
    margin-bottom: 15px;
  }
}

.job-billing-wrap {
  @media screen and (min-width: 1200px) {
    .ant-col {
      &:first-child .job-billing-card {
        border-radius: 4px 0 0 4px;
      }
      &:nth-child(3n) {
        .job-billing-card {
          border-radius: 0 4px 4px 0 !important;
        }
        & + .ant-col .job-billing-card {
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    .ant-col {
      &:first-child .job-billing-card {
        border-radius: 4px 0 0 4px;
      }
      &:nth-child(2n) {
        .job-billing-card {
          border-radius: 0 4px 4px 0 !important;
        }
        & + .ant-col .job-billing-card {
          border-radius: 4px 0 0 4px;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .job-billing-card {
      border-radius: 4px;
    }
  }
}
.userInfoListing {
  .cnt-wrap {
    max-width: 630px;
    width: 100%;
  }
  .ant-list-items {
    max-height: 150px;
    overflow: auto;
  }
  .ant-list-item {
    @extend .d-flex, .justify-content-between;
  }
}
.client-details {
  .ant-page-header-heading-left {
    flex-wrap: nowrap !important;
  }
  .ant-page-header-back {
    margin: 3px 0 0 0 !important;
  }
  .ant-page-header-heading-title {
    margin-left: 24px !important;
    display: flex !important;
  }
  .icon-view-wrap {
    display: flex;
    align-items: center;
    margin-right: 24px;
    i {
      color: #0b1e60;
    }
  }
}
.client-page-head {
  .ant-page-header-heading-title {
    display: flex;
  }
}
.open-flag {
  display: flex;
  font-size: 12px !important;
  align-items: center;
  margin-left: 12px;
  .dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: palegreen;
    border-radius: 100%;
    position: relative;
    top: 0;
  }
}

.closed-flag {
  display: flex;
  font-size: 12px !important;
  align-items: center;
  margin-left: 12px;
  .dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: #ff0000;
    border-radius: 100%;
    position: relative;
    top: 0;
  }
}

.onhold-flag {
  display: flex;
  font-size: 12px !important;
  align-items: center;
  margin-left: 12px;
  .dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: #ffc185;
    border-radius: 100%;
    position: relative;
    top: 0;
  }
}
.blue-flag {
  display: flex;
  font-size: 12px !important;
  align-items: center;
  margin-left: 12px;
  .dot {
    width: 8px;
    height: 8px;
    margin-right: 5px;
    background-color: #0093c8;
    border-radius: 100%;
    position: relative;
    top: 0;
  }
}

.custom-user-select {
  .client-detail {
    white-space: break-spaces;
    font-weight: 600;
    font-size: 14px;
  }
  .client-name {
    display: block;
    font-size: 12px;
    font-weight: 500 !important;
    &:first-child {
      display: inline;
    }
  }
}

.hiring-manager {
  display: flex;
  .create-btn {
    margin-left: 10px;
  }
}

.threshold-wrap {
  display: flex;
  width: 100%;
  @media screen and (min-width: 601px) {
    gap: 16px;
  }
  @media screen and (max-width: 600px) {
    gap: 5px;
  }
  align-items: center;
}

.teir-list {
  @media screen and (min-width: 601px) {
    min-width: 121px;
  }
  li.ant-list-item {
    font-weight: 500;
    border-bottom: 1px solid rgba(39, 141, 192, 0.16);
    &:last-child {
      border-bottom: 1px solid rgba(39, 141, 192, 0.16) !important;
    }
  }
}

.threshold-card-wrap {
  display: flex;
  gap: 16px;
  @media screen and (min-width: 601px) {
    max-width: calc(100% - 137px);
  }
  @media screen and (max-width: 600px) {
    flex: 1;
  }
  overflow: auto;
}

.threshold-card {
  min-width: 235px;
  max-width: 235px;
  border: 1px solid #278dc01f;
  background: #f9fdff;
  align-self: flex-start;
  .ant-form-item {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .ant-card-head {
    border-bottom: 1px solid rgba(39, 141, 192, 0.12);
    &-title {
      padding: 13px 0;
      color: #278dc0;
      font-weight: 500;
    }
  }
  .field-wrap {
    margin-left: 3px;
  }
  .count-field {
    width: 50px;
    height: 30px;
    border: 1px solid rgba(39, 141, 192, 0.5);
    color: #000;
    .ant-input-number-input {
      height: 28px;
    }
  }
  input.ptage-field {
    margin-bottom: 8px;
  }
  .ptage-field {
    &.ant-input-number-disabled,
    &.ant-input-disabled {
      color: #278dc0;
      border: 1px solid #278dc0;
      background-color: #e7f7ff;
    }
  }
  .ant-card-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 18px;
    background: #f9fdff;
    padding: 0;
    padding-left: 20px;
    min-height: 40px;
    &:after {
      display: none;
    }
    .del-icon {
      border-left: 1px solid #f0f0f0;
      min-width: 50px;
      .icon-del-threshold {
        font-size: 13px;
      }
    }
    .del-icon,
    .del-icon:hover {
      color: #ff6550;
    }
    &:before {
      display: none;
    }
    li {
      width: auto !important;
      border-right: none;
      margin: 0;
      line-height: inherit;
      span {
        line-height: inherit;
      }
      svg {
        line-height: initial;
      }
      .ant-checkbox-wrapper {
        font-size: 12px;
      }
    }
  }
}

.new-threshold {
  font-weight: 600;
  @media screen and (min-width: 601px) {
    min-width: 158px;
  }
  span {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  @media screen and (max-width: 600px) {
    padding: 4px 6px;
  }
  align-self: center;
  min-height: 214px;
  border-color: rgba(39, 141, 192, 0.5);
  color: #278dc0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .icon-plus {
    font-size: 18px;
    margin-bottom: 8px;
  }
}
.billing-approval-header {
  position: relative;
  .ant-spin {
    position: initial !important;
  }
}

.dashboard-input-spinner {
  position: initial !important;
  .ant-spin-dot {
    margin-top: -8px;
  }
}

.section-spinner {
  position: initial !important;
}

.delete-job-head {
  .ant-page-header-heading-left {
    flex-wrap: nowrap;
  }
  .ant-page-header-heading-title {
    display: flex !important;
    width: auto !important;
    margin-left: 0 !important;
  }
}

.no-of-submitText {
  .ant-form-item-label {
    justify-content: center;
  }
}

.cancelBilling {
  .ant-modal-title {
    color: #278dc0 !important;
  }
}
.clearSort {
  background-color: white;
  border-radius: 4px;
  box-shadow: none;
  margin-left: 10px;

  &:not(:disabled) {
    color: #ff6550;
    border-color: #ff6550;
  }
}

.job-detail-list {
  .ant-list-item {
    border: none;
    padding: 10px 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
    }
  }
}

.job-detail-card {
  width: 100%;
  .ant-card-head {
    font-size: 14px;
    background-color: #e3eff6;
    &-title,
    .ant-card-extra {
      padding: 14px 0;
    }
    .sub-text {
      font-size: 12px;
      font-weight: 400;
    }
  }
}

.g2-tooltip-ot {
  .list-wrap {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
  li {
    margin: 12px 0px;

    padding: 0px;
  }
  .g2-tooltip-marker {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
  }
}

.credit-modal {
  .ant-modal-title {
    color: #4d72be !important;
  }
  .clientBilling {
    display: flex;
    justify-content: right;
  }
  .credit-text {
    font-size: 14px;
  }
}

.issue-credit-head {
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
.issue-btn-wrap {
  display: flex;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}

.relative-spinner {
  &.ant-spin {
    position: absolute !important;
  }
}

.reason-field-row {
  margin-bottom: 10px;
  .ant-row {
    flex-direction: row !important;
    .ant-form-item-label > label::after {
      // display: contents;
    }
  }
}

.reason-field {
  margin-bottom: 10px;
  .ant-row {
    flex-direction: column !important;
    .ant-form-item-label > label::after {
      display: contents;
    }
  }
}
.hover-underlined:hover {
  cursor: pointer;
  text-decoration: underline !important;
  white-space: nowrap; /* Prevent text from wrapping to the next line */
  overflow: hidden; /* Hide any content that overflows the container */
  text-overflow: ellipsis;
  color: #0b0080 !important;
}
.cursor-pointer {
  cursor: pointer;
}

.client-list-tooltip {
  padding: 0 5px;
  border-radius: 3px;
  background-color: #fff;
  border: solid 1px gray;
}

.view-activity-btn {
  span:hover {
    text-decoration: underline;
  }
}

.answer-field-wrap {
  margin-bottom: 20px;
  border-radius: 6px;
  padding: 16px;
  border: dashed 1px #dddddd;
  .ant-form-item {
    margin-bottom: 12px;
  }
  .icon-del-threshold {
    font-size: 15px;
    color: #ff6550;
    margin-top: 8px;
  }
}

.delete-btn {
  color: #ff6550 !important;
}
.sider-tag {
  font-weight: 700;
  margin-right: 0;
  min-height: 14px;
  font-size: 10px;
  color: #4d72be;
  max-width: 24px;
  line-height: 1.5;
  padding: 0px 6px;
  border-radius: 4px;
  background: #f4f6ff;
}
.sider-text {
  font-size: 14px;
}
.candidates-modals {
  .ant-modal-header {
    padding: 0;
    border-bottom: none;
  }
  .ant-modal-title {
    color: #1b3799 !important;
    font-weight: 700 !important;
    font-size: 1.25rem !important;
    padding: 1.5rem 1.5rem 2rem;
  }
  .instructions {
    font-size: 1rem;
    color: black;
  }
}

.btn-detached {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 2.4px;
}
.icon-detach {
  font-size: 20px !important;
  margin-top: 2px;
}
.cp-list {
  height: calc(100vh - 190px);
  overflow: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}

.transfer-candidate {
  .ant-transfer-list {
    height: 400px;
    flex: 1;
  }
}
.candidate-status-detail {
  @media screen and (max-width: 470px) {
    gap: 1rem;
  }
  .ant-select {
    @media screen and (max-width: 470px) {
      width: 100%;
    }
  }
}
// .ag-theme-alpine {
//   .ag-paging-panel {
//     position: relative;
//     flex-wrap: wrap;
//   }
//   .ag-status-bar {
//     @media screen and (min-width: 576px) {
//       position: absolute;
//       z-index: 2;
//       left: -5px;
//       bottom: 0;
//       border-top: none;

//       .ant-select-arrow {
//         top: 40%;
//       }
//     }

//     @media screen and (max-width: 576px) {
//       justify-content: end;
//       padding-top: 1rem;
//     }
//   }
// }
.table-pagination {
  .ag-theme-alpine {
    .ag-paging-panel {
      position: relative;
      flex-wrap: wrap;
      @media screen and (min-width: 576px) and (max-width: 787px) {
        right: 24%;
      }
      @media screen and (min-width: 786px) and (max-width: 1299px) {
        right: 20%;
      }
      @media screen and (min-width: 1300px) {
        right: 15%;
      }
    }
    .ag-status-bar {
      @media screen and (min-width: 576px) {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        border-top: none;
        margin-left: 10px;
        .ant-select-arrow {
          top: 40%;
        }
      }

      @media screen and (max-width: 576px) {
        justify-content: end;
        padding-top: 1rem;
      }
    }
  }
}
.display-udf-form {
  .ant-form-item {
    margin-bottom: 6px;
  }
}
.viewActivityCol {
  .ant-col {
    @media screen and (max-width: 575px) {
      width: 100%;
    }
  }
}
.client-card-header-info {
  .right-side-info {
    gap: 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem !important;
    @media screen and (max-width: 1300px) {
      width: 100%;
    }
    :not(:last-child) {
      @media screen and (min-width: 576px) and (max-width: 1300px) {
        flex: 1;
      }
    }
    .ant-select {
      width: 200px;
      flex: 1;
      height: 32px;
    }
    .ant-btn {
      @media screen and (max-width: 575px) {
        width: 100%;
      }
    }
  }
}
.client-job-list {
  .ant-card-head-wrapper {
    @media screen and (max-width: 1200px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  .ant-card-extra {
    @media screen and (max-width: 1200px) {
      margin-left: 0;
      width: 100%;
    }
    .right-side-inner {
      @media screen and (max-width: 1200px) {
        flex-wrap: wrap;
      }
      :not(:last-child) {
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          flex: 1;
        }
      }
      .ant-input-group-wrapper,
      .ant-select {
        @media screen and (min-width: 768px) and (max-width: 1200px) {
          width: 264px !important;
        }
      }
    }
  }
}
.custom-max-tag-count {
  color: white; /* Change this to your desired color */
  background-color: #1b3799; /* Optional: change the background color */
  padding: 2px 5px;
  border-radius: 3px;
}

.env-header {
  height: 25px;
  background-size: 200% 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  font-weight: bold;
  text-align: center;

  .ant-alert-message {
    color: #0e1e60;
    font-weight: bold;
  }
}
